<template>
  <div>
    <!-- <img class="demo" src="/img/demo/case.jpg" /> -->
    <img class="block p1" src="/img/case/1.jpg" />
    <div class="overflow-hidden relative p2">
      <img class="float-right block" src="/img/case/2.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/2-1.png" />
      </div>
    </div>
    <div class="relative p3">
      <img class="block p3" src="/img/case/3.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/3-1.png" />
      </div>
    </div>

    <div class="overflow-hidden relative p4">
      <img class="float-right block" src="/img/case/4.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/4-1.png" />
      </div>
      <div class="absolute absolute2">
        <img data-aos="fade-up" class="block" src="/img/case/4-2.png" />
      </div>
    </div>
    <img class="block p5" src="/img/case/5.jpg" />

    <div class="overflow-hidden relative p6">
      <img class="float-right block" src="/img/case/6.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/6-1.png" />
      </div>
    </div>
    <div class="relative p7">
      <img class="block" src="/img/case/7.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/7-1.png" />
      </div>
    </div>

    <div class="overflow-hidden relative p8">
      <img class="float-right block" src="/img/case/8.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/8-1.png" />
      </div>
    </div>
    <div class="relative p9">
      <img class="block" src="/img/case/9.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/9-1.png" />
      </div>
    </div>

    <div class="overflow-hidden relative p10">
      <img class="float-right block" src="/img/case/10.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" class="block" src="/img/case/10-1.png" />
      </div>
    </div>
  </div>
</template>

<script>
  import aos from '@/mixins/aos'
  export default {
    mixins: [aos],
  }
</script>

<style lang="scss" scoped>
  .p2 {
    margin-top: 80px;
    & > img {
      width: 1700px;
    }
    .absolute {
      top: 5.9%;
      left: 11%;
      width: 30.6%;
    }
  }
  .p3 {
    & > img {
      width: 1700px;
      margin-top: 220px;
    }
    .absolute {
      top: 6.6%;
      left: 60%;
      width: 30.6%;
    }
  }
  .p4 {
    margin-top: 210px;
    & > img {
      width: 100vw;
    }
    .absolute {
      top: 3.1%;
      left: 11%;
      width: 31.3%;
    }
    .absolute2 {
      top: 43.8%;
      left: 60.2%;
      width: 31.4%;
    }
  }
  .p5 {
    margin-top: 220px;
  }
  .p6 {
    margin-top: 170px;
    & > img {
      width: 1700px;
    }
    .absolute {
      top: 3.4%;
      left: 11%;
      width: 27.4%;
    }
  }
  .p7 {
    background-color: #f4f4f9;
    margin-top: 186px;
    & > img {
      width: 1875px;
    }
    .absolute {
      top: 10.8%;
      left: 58.8%;
      width: 20.9%;
    }
  }
  .p8 {
    margin-top: 84px;
    & > img {
      width: 1697px;
    }
    .absolute {
      top: 0.4%;
      left: 11%;
      width: 20.8%;
    }
  }
  .p9 {
    background-color: #f4f4f9;
    margin-top: 160px;
    & > img {
      width: 1900px;
    }
    .absolute {
      top: 10%;
      left: 60.2%;
      width: 21.1%;
    }
  }
  .p10 {
    margin-top: 120px;
    margin-bottom: 180px;
    & > img {
      width: 1700px;
    }
    .absolute {
      top: 2.7%;
      left: 11%;
      width: 20.5%;
    }
  }
</style>
